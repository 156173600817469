.learning {
    &-narrator {
        background-image: url('../../Images/backgrounds/background_art.png');
        background-size: cover;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        &.castle {
            background-image: url('../../Images/backgrounds/hero_mirrored.png');
        }
        &__dude {
            position: relative;
            height: K(80%);
        }
        &__img {
            height: 100%;
        }
        .replic {
            left: 85%;
            top: 0;
            transform: translate(0%, -10%);
            width: K(800px);
            height: K(300px);
            padding: K(100px);
            font-size: K(25px);
        }
        &__msg {
            position: absolute;
            left: 85%;
            top: 0;
            transform: translate(0%, -10%);
            transform-style: preserve-3d;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
                39.3deg,
                #ffffff 19.53%,
                #f9fbfc 34.04%,
                #e7eef2 54.16%,
                #c9dae3 77.48%,
                #c2d5df 82.54%
            );
            width: K(800px);
            height: K(300px);
            padding: K(100px);
            border-radius: K(250px);
            font-size: K(25px);
            color: black;
            text-align: center;
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                opacity: 0.8;
                left: K(40px);
                transform: translateY(50%) translateZ(-1px);
                background-repeat: no-repeat;
                background-size: contain;
                width: K(100px);
                height: K(100px);
                background-image: url('../../Images/ui/ui_diaglog_tooltip.png');
            }
        }
        &__btn {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 20px;
            .button-normal {
                font-size: K(30px);
            }
        }
    }
}

.intent-top {
    margin-top: K(40px);
    height: -webkit-fill-available;
}

.choose {
    width: 100%;
    height: 100%;
    position: relative;
    background: linear-gradient(
        90deg,
        rgba(146, 146, 146, 0) 0%,
        rgba(70, 70, 70, 0.61) 53.44%,
        rgba(146, 146, 146, 0) 100%
    );
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: K(35px);
        color: #fff;
        height: 10%;
        &:before {
            @include BGG(90deg, 2px, 100%, 0, 0);
        }
        &:after {
            @include BGG(90deg, 2px, 100%, 100%, 0);
        }
    }
    &__body {
        width: 100%;
        height: 90%;
        display: flex;
        padding: K(20px) 0;
        align-items: flex-end;
        position: relative;
        &-fullheight {
            height: 100%;
        }
        &:before {
            //@include BGG(0deg, 100%, 2px, 0, 99.5%);
            @include BGG(90deg, 2px, 100%, 0, 0);
        }
        &:after {
            @include BGG(90deg, 2px, 100%, 99.5%, 0);
        }
    }
    &__image {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 K(40px);
        &:before {
            @include BGG(0deg, 55%, 2px, 45%, 99.5%);
        }
        .replic {
            left: 100%;
        }
        > img {
            height: 100%;
            object-fit: contain;
        }
        &.hero {
            max-width: K(600px);
            width: 100%;
            &:before {
                @include BGG(0deg, 100%, 2px, 0, 99.5%);
            }
            > img {
                height: 80%;
            }
        }
        &.flag {
            &:before {
                @include BGG(0deg, 100%, 2px, 0, 0);
            }
            &:after {
                @include BGG(0deg, 100%, 2px, 0, 99.5%);
            }
            > img {
                height: 75%;
            }
        }
    }
    &__description {
        padding: 0 K(40px);
        width: -webkit-fill-available;
        &.hero {
            padding: 0 K(120px);
            width: 100%;
            height: 100%;
            &:before {
                @include BGG(0deg, 100%, 2px, 0, 99.5%);
            }
            .hero_description {
                font-size: K(20px);
                color: #fff;
                text-align: center;
                line-height: 1.4;
                margin: K(40px) 0;
            }
            .hero_stats {
                .heroes-hero-info__stat {
                    color: #ffa593;
                    width: 33%;
                    margin-bottom: K(30px);
                    .heroes-hero-info__num {
                        color: #fff;
                    }
                }
            }
        }

        &_header {
            text-align: center;
            position: relative;
            font-size: K(35px);
            color: #fff;
            margin-bottom: K(20px);
        }
    }
    .button-normal {
        font-size: K(30px);
    }
    .choose-bonus {
        display: flex;
        align-items: center;
        margin-bottom: K(10px);
        color: #fff;
        &-text {
            font-size: K(15px);
            line-height: 1.3;
            margin-left: K(40px);
        }
        &-icon {
            background: linear-gradient(
                180deg,
                rgba(62, 51, 66, 0) 0%,
                #3e3342 100%
            );
            border: 1.33356px solid #b5b75b;
            box-shadow: inset 0px 3.3348px 3.3348px rgba(0, 0, 0, 0.25),
                inset 0px 3.3348px 30.8469px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            width: K(80px);
            object-fit: contain;
        }
    }
}
