.popup {
    position: fixed;
    z-index: $popupZIndex;
    width: 100%;
    top: 0;
    bottom: 0;
    @include BGI('../Images/backgrounds/popup.png');
    transition: transform 0.3s;
    max-height: 100%;
    flex-direction: column;
    display: none;

    &-show {
        display: flex;
    }

    &_heroes {
        background-image: url('../../Images/backgrounds/hero.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #000;
    }

    &_fraction {
        background-image: url('../../Images/backgrounds/hero_mirrored.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #000;
    }

    &_gold {
        background-image: url('../../Images/backgrounds/gold.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #000;
    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        color: white;
        font-size: K(30px);
        margin-top: K(20px);
        padding: 0 K(100px);
        background: linear-gradient(
            90deg,
            rgba(28, 26, 38, 0) 0%,
            #1c1a26 50%,
            rgba(28, 26, 38, 0) 100%
        );

        &:before {
            @include BGGold(90deg, 2px, 100%, 0, 0);
        }

        &:after {
            @include BGGold(90deg, 2px, 100%, 100%, 0);
        }

        &-linear {
            width: 100%;
            background: transparent;
            margin-top: 0;
            padding: 0;
            justify-content: center;
            &:after {
                display: none;
            }
            &:before {
                display: none;
            }
        }
    }

    &__bg {
        z-index: $popupZIndex;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        visibility: hidden;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.3s;

        &-show {
            opacity: 1;
            visibility: visible;
        }
    }

    &__header {
        display: flex;
        align-items: stretch;
        min-height: K(70px);
        margin-bottom: K(20px);

        &-linear {
            flex-direction: column;
            min-height: 0;
            margin-bottom: 0;

            &:after {
                content: '';
                background-image: url('../../Images/popup/header_linear.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: K(100px);
                width: 100%;
                margin-top: K(10px);
            }
        }
    }

    &__close {
        height: K(70px);
        aspect-ratio: 150/124;
        cursor: pointer;
        margin-left: auto;
        background-image: url('../../Images/ui/popup_close.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        &-linear {
            position: absolute;
            top: 0;
            right: 0;
            // background-image: none;
        }

        // &:hover {
        //     background: rgba(128, 128, 128, 0.096);
        // }
    }

    &__body {
        height: 100%;
        overflow-y: auto;
    }

    &-inner {
        display: flex;
        height: 100%;

        &__body {
            flex: 1;
            overflow-x: hidden;
        }

        &__tabs {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &.tabs-quest {
                margin-bottom: K(50px);
            }
        }

        &__button {
            position: relative;
            overflow: hidden;
            width: K(90px);
            height: K(50px);
            margin-top: K(10px);
            font-size: K(20px);

            > .popup_button {
                position: absolute;
            }
        }

        &__tab {
            width: K(90px);
            height: K(90px);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            overflow: hidden;
            position: relative;
            flex-direction: column;
            color: white;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(196, 196, 196, 0.3);
            }

            // @include BGI('../Images/ui/bg_popup_tab.png');

            // &-active {
            //     @include BGI('../Images/ui/bg_popup_tab_active.png');
            // }

            &-active {
                color: #f6e19c;
                &:after {
                    position: absolute;
                    right: 0;
                    height: 100%;
                    content: '';
                    @include BGI('../Images/ui/popup_tab_line_focus.png');
                    width: 10px;
                }
            }
        }

        &__icon {
            height: K(30px);

            img {
                height: 100%;
            }
        }

        &__name {
            font-size: K(13px);
            line-height: K(20px);
            color: inherit;
            text-transform: uppercase;
            font-weight: 400;
        }
    }

    &_castle,
    &_fraction,
    &_lootbox {
        padding-bottom: K(40px);
    }
}
