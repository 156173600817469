.input {
    width: 100%;
    // @include UIGex(10px);
    // background: #16141d;
    // border: 2px solid #57495f;
    @include BGI('../Images/ui/bg_input.png');
    input {
        padding: K(10px);
        font-size: K(15px);
        width: 100%;
        background: transparent;
        color: white;

        &:disabled {
            color: grey;
        }
    }
}
