.castle-slider {
    user-select: none;
    width: 100%;
    overflow: hidden;
    padding: 2vh;

    &__list {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: scroll;
    }

    &__downinfo {
        font-size: 3vh;
        margin-top: 3vh;
        text-align: center;
        line-height: 6vh;

        span {
            color: #f0a91f;
        }
    }

    &__upinfo {
        position: absolute;
        top: -4%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        aspect-ratio: 1/0.4;
        background-image: url('../../../Images/ui/ribbon.png');
        background-size: contain;
        background-position: center;

        &:after {
            content: attr(data-value);
            font-size: 3vh;
            position: absolute;
            left: 50%;
            top: 35%;
            transform: translateX(-50%);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        color: #fff;
        height: 80vh;
        aspect-ratio: 1/1.65;
        padding: 2vh;
        background-image: url('../../../Images/ui/bg_popup_tab_castle.png');
        background-size: 100%;
        filter: drop-shadow(-2px -2px -2px #ffb400);
        transition: filter 0.2s;

        &-kingticket {
        }

        &-disabled {
            filter: brightness(0.5);
            &:after {
                content: '';
                position: absolute;
                background-image: url('../../../Images/icons/lock.png');
                width: 15vh;
                aspect-ratio: 1/1;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &:not(&-disabled) {
            cursor: pointer;

            &:hover {
                filter: drop-shadow(2px 4px 6px #ffb400);
            }
        }

        &.items-center {
            justify-content: center;
        }

        &_position {
            &-marginRight {
                margin-right: 1.5vh;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__label {
        position: absolute;
        top: -3%;
        left: -2%;
        width: 106%;
        padding-bottom: 50%;
        @include BGI('../Images/ui/ui_card_label.png');
        background-size: contain;

        &-text {
            position: absolute;
            text-align: center;
            width: 100%;
            transform: translate(0, -50%);
            font-size: K(30px);
            top: 25%;
        }
    }

    &__icon {
        //padding: K(45px);
        pointer-events: none;
        width: 80%;

        &.glow {
            position: relative;
            z-index: 1;

            &:before {
                position: absolute;
                content: '';
                z-index: -1;
                width: 100%;
                height: 100%;
                background: radial-gradient(
                    50% 50% at 50% 50%,
                    #ffdb71 0%,
                    rgba(255, 236, 181, 0) 92.71%
                );
                filter: blur(50px);
            }
        }

        &.align-self {
            margin-top: K(30px);
        }

        img {
            width: 100%;
        }
    }

    &__name {
        font-size: 4vh;
        padding: 2vh;
        width: 100%;
        text-align: center;
        font-weight: 400;

        &.align-self {
            margin-top: 8vh;
        }

        // &.glow {
        //     background: linear-gradient(
        //         90deg,
        //         rgba(146, 146, 146, 0) 0%,
        //         rgba(90, 90, 90, 0.5) 50%,
        //         rgba(178, 178, 178, 0) 100%
        //     );
        // }
    }

    &__lootboxPower {
        padding: K(10px);
        position: absolute;
        display: flex;
        color: #000;
        justify-content: center;
        height: 10%;
        width: 15%;
        font-size: K(30px);
        @include BGI('../Images/ui/yellow_arrow.png');

        &_position {
            &-topRight {
                top: 4%;
                right: 14%;
            }
        }
    }

    &__lootbox-amount {
        font-size: K(35px);
    }
}
