@import '~toastr/toastr.scss';

@import './Core/reset.scss';
@import './Core/loader.scss';
//TODO Удалять???
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import 'vars';
@import 'mixins';
@import './UI/index.scss';
@import './Game/gex.scss';
@import './Game/map.scss';
@import './Game/tile.scss';
@import './Components/item.scss';
@import './Components/ux.scss';
@import './Components/user.scss';
@import './UI/popup.scss';
@import './Components/inventory.scss';
@import './Components/heroes.scss';
@import './UI/form.scss';
@import './Components/missions.scss';
@import './Components/castle/castle.scss';
@import './Components/castle/hallOfKeys/lootboxes.scss';
@import './Components/help.scss';
@import './Components/npc.scss';
@import './Components/card.scss';
@import './UI/checkbox.scss';
@import './UI/line.scss';
@import './UI/textured';
@import './Components/quest.scss';
@import './Game/learning.scss';
@import './Components/replic.scss';
@import './Components/roulette.scss';
@import './Components/settings.scss';
@import './Components/sponsor.scss';
@import './Components/profile.scss';
@import './Components/referrals.scss';
@import './Components/kingLevel.scss';
@import './Core/beta.scss';

* {
    font-family: $alata;
    @include scrollbars(8px, rgba(196, 196, 196, 0.53), rgba(0, 0, 0, 0.3));
}
#card {
    position: relative;
    z-index: $screenCardZIndex;
}
#tooltip {
    position: relative;
    z-index: 100;
}
body {
    background: #000;
    overflow: hidden;
}
.window {
    width: 100%;
    height: 100%;
    max-width: VH(100);
    // max-height: calc(100vw * (9/16));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
