$uxZIndex: 4;
$popupZIndex: 5;
$tooltipZIndex: 7;
$screenCardZIndex: 6;
$mapZIndex: 2;
$alata: 'Alata', sans-serif;

:root {
    --k-size: 1;

    @media (max-width: 1000px) {
        --k-size: 0.7;
    }
    @media (max-height: 600px) {
        --k-size: 0.7;
    }
    @media (max-height: 450px) {
        --k-size: 0.5;
    }
}
