.referrals {
    height: calc(100vh - #{K(450px)});
    overflow-y: auto;
}

.referral {
    display: flex;
    align-items: center;
    padding: K(10px);
    background-image: url('../../Images/ui/profile_rect.png');
    background-size: 100% 100%;
    margin-bottom: K(20px);

    &__avatar {
        width: K(80px);
        height: K(80px);
        padding: K(8px);
    }

    &__text {
        margin: K(10px) 0;
        color: white;
    }

    &__nickname {
        color: #ffc83b;
    }

    &__data {
        flex: 1;
        overflow: hidden;
        padding-left: K(10px);
    }

    &-add {
        background: rgba(0, 0, 0, 0.3);
        color: white;
        display: flex;
        padding: K(15px) K(40px);
        border-radius: K(5px);
        align-items: center;

        &__text {
            color: #ff9900;
            padding-right: K(20px);
            font-size: K(20px);
            line-height: K(25px);
        }

        &__value {
            font-size: K(35px);
            line-height: K(35px);
        }
    }
}
