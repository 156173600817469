.profile {
    padding: K(20px);

    &__h {
        font-size: K(43px);
        line-height: K(59px);
        margin-top: K(20px);
        color: #ffffff;
    }

    &-main {
        border-radius: K(10px);
        padding: K(20px);
        display: flex;
        align-items: center;
        background-image: url('../../Images/ui/profile_rect.png');
        background-size: 100% 100%;

        &__nickname {
            color: #ffc83b;
            font-size: K(36px);
            line-height: K(40px);
        }

        &__id {
            color: #b0b0b0;
            margin: K(10px) 0;

            span {
                color: white;
            }
        }

        &__info {
            padding-left: K(20px);
            flex: 1;
        }

        &__kinglevel {
            position: absolute;
            right: K(-10px);
            color: white;
            font-size: K(25px);
            top: 50%;
            transform: translateY(-50%);
            width: K(100px);
            height: K(100px);
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('../../Images/ui/bg_vip.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__power {
            position: relative;
            max-width: 1000px;
        }
        &__copy {
            padding-left: K(10px);
            cursor: pointer;
            &:hover {
                color: grey;
            }
        }
    }

    &-avatar {
        background-image: url('../../Images/ui/bg_hero_avatar.png');
        width: K(200px);
        height: K(200px);
        padding: K(25px);
        background-size: 100% 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
