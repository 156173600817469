.user {
    &-bar {
        $vipSize: 70px;
        $padding: 10px;
        z-index: $uxZIndex;
        position: fixed;
        top: K(10px);
        left: K(10px);
        background-image: url('../../Images/ui/bg_userbar.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding: 1.4vh;
        padding-right: 5vh;
        // padding-right: K($vipSize / 2 + $padding);
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
        width: 50vh;
        aspect-ratio: 10/2;

        &__progress {
            height: 70% !important;
            aspect-ratio: 10/0.8;
            margin-left: K(-14px);

            .progress-bar-gexed__value {
                font-size: 1vh;
            }
        }

        &__avatar {
            border-radius: 10px;
            background: #2f2532;
            border: 2px solid black;
            overflow: hidden;
            position: relative;
            z-index: 2;
            height: 100%;
            aspect-ratio: 1/1;
            padding: 0.5vh;

            img {
                border-radius: 5px;
                height: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }

        &__name {
            color: white;
            font-size: 1.5vh;
            padding-bottom: $padding;
            padding-left: $padding;
        }

        &__info {
            flex: 1;
        }

        &__vip {
            background: url('../../Images/ui/bg_vip.png');
            height: 90%;
            aspect-ratio: 1/1;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            position: absolute;
            right: 1.4vh;
            font-size: 2.5vh;
        }
    }

    &-money {
        position: fixed;
        top: K(20px);
        right: 0;
        z-index: $uxZIndex;

        &__block {
            margin-right: K(20px);
            position: relative;
        }

        &__icon {
            position: absolute;
            width: K(50px);
            left: 0;
            top: K(50%);
            transform: translate(-50%, -50%);
        }

        &__count {
            $rounded: 13px;
            color: white;
            padding: K(10px) K(20px) K(10px) K(30px);
            background: rgba(0, 0, 0, 0.5);
            font-size: K(15px);
            @include UIGex(K(10px));
        }
    }
}
