@mixin UIGex($rounded) {
    clip-path: polygon(
        #{$rounded} 0%,
        calc(100% - #{$rounded}) 0%,
        100% 50%,
        calc(100% - #{$rounded}) 100%,
        #{$rounded} 100%,
        0% 50%
    );
}

@mixin BGI($url) {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@function K($px) {
    @return calc(#{$px} * var(--k-size));
}

@function VH($size) {
    @return calc(#{$size}vh * (16 / 9));
}

@mixin inventoryItemBGPreset {
    &-equipment {
        &__rare {
            @include BGI('../Images/equipments/equipment_frame_red.png');
        }

        &__mythical {
            @include BGI('../Images/equipments/equipment_frame_blue.png');
        }

        &__epic {
            @include BGI('../Images/equipments/equipment_frame_purple.png');
        }

        &__legendary {
            @include BGI('../Images/equipments/equipment_frame_gold.png');
        }

        &__common {
            @include BGI('../Images/equipments/equipment_frame_silver.png');
        }
    }
    &-resource,
    &-lootbox,
    &-heroegg {
        &__rare {
            @include BGI('../Images/ui/resource_rare_frame.png');
        }

        &__mythical {
            @include BGI('../Images/ui/resource_mystic_frame.png');
        }

        &__epic {
            @include BGI('../Images/ui/resource_epic_frame.png');
        }

        &__legendary {
            @include BGI('../Images/ui/resource_legendary_frame.png');
        }

        &__common {
            @include BGI('../Images/ui/resource_common_frame.png');
        }
    }
}

@mixin scrollbars(
    $size,
    $foreground-color,
    $background-color: mix($foreground-color, white, 50%)
) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $size;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: $size;
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin borderGradient($gradient) {
    border-image-source: $gradient;
    border-image-slice: 1;
}

@mixin BGG($deg, $h, $w, $t, $l) {
    content: '';
    position: absolute;
    top: $t;
    left: $l;
    width: $w;
    height: $h;
    background: linear-gradient(
        $deg,
        rgba(148, 148, 148, 0) 0%,
        #cbcbcb 50.83%,
        rgba(148, 148, 148, 0) 100%
    );
}

@mixin BGGold($deg, $h, $w, $t, $l) {
    @include BGG($deg, $h, $w, $t, $l);
    background: linear-gradient(
        90deg,
        rgba(182, 147, 57, 0) 0%,
        #b69339 50%,
        rgba(182, 147, 57, 0) 100%
    );
}
