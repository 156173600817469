.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    &-screen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../Images/backgrounds/background_art.png');
        background-size: cover;
        background-position: center;

        &__header {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
            background-image: url('../../Images/ui/ribbon_main.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 10vh;
            aspect-ratio: 6279/811;
        }
        &__inner {
            position: absolute;
            top: 25%;
            font-size: 3vh;
            color: #eeab00;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-2 {
        $k: 1;
        .lds-default {
            display: inline-block;
            position: relative;
            width: 80px * $k;
            height: 80px * $k;
        }

        .lds-default div {
            position: absolute;
            width: 6px * $k;
            height: 6px * $k;
            background: #fff;
            border-radius: 50%;
            animation: lds-default 1.2s linear infinite;
        }

        .lds-default div:nth-child(1) {
            animation-delay: 0s;
            top: 37px * $k;
            left: 66px * $k;
        }

        .lds-default div:nth-child(2) {
            animation-delay: -0.1s;
            top: 22px * $k;
            left: 62px * $k;
        }

        .lds-default div:nth-child(3) {
            animation-delay: -0.2s;
            top: 11px * $k;
            left: 52px * $k;
        }

        .lds-default div:nth-child(4) {
            animation-delay: -0.3s;
            top: 7px * $k;
            left: 37px * $k;
        }

        .lds-default div:nth-child(5) {
            animation-delay: -0.4s;
            top: 11px * $k;
            left: 22px * $k;
        }

        .lds-default div:nth-child(6) {
            animation-delay: -0.5s;
            top: 22px * $k;
            left: 11px * $k;
        }

        .lds-default div:nth-child(7) {
            animation-delay: -0.6s;
            top: 37px * $k;
            left: 7px * $k;
        }

        .lds-default div:nth-child(8) {
            animation-delay: -0.7s;
            top: 52px * $k;
            left: 11px * $k;
        }

        .lds-default div:nth-child(9) {
            animation-delay: -0.8s;
            top: 62px * $k;
            left: 22px * $k;
        }

        .lds-default div:nth-child(10) {
            animation-delay: -0.9s;
            top: 66px * $k;
            left: 37px * $k;
        }

        .lds-default div:nth-child(11) {
            animation-delay: -1s;
            top: 62px * $k;
            left: 52px * $k;
        }

        .lds-default div:nth-child(12) {
            animation-delay: -1.1s;
            top: 52px * $k;
            left: 62px * $k;
        }

        @keyframes lds-default {
            0%,
            20%,
            80%,
            100% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.5);
            }
        }
    }
}
