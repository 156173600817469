.heroes {
    display: flex;
    height: 100%;

    &-list {
        height: 100%;

        &__item {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: K(4px);
            padding: K(5px);
            color: white;

            &-disabled {
                background: rgb(126, 126, 126);
                cursor: initial;
            }
            &-rare {
                background: linear-gradient(
                    90deg,
                    rgba(216, 40, 40, 0.06) 0%,
                    rgba(216, 40, 40, 0.35) 50%,
                    rgba(216, 40, 40, 0.06) 100%
                );
            }
            &-mythical {
                background: linear-gradient(
                    90deg,
                    rgba(80, 0, 255, 0) 0%,
                    rgba(80, 0, 255, 0.5) 49.42%,
                    rgba(80, 0, 255, 0) 100%
                );
            }
            &-epic {
                background: linear-gradient(
                    90deg,
                    rgba(173, 0, 255, 0) 0%,
                    rgba(185, 58, 245, 0.5) 50%,
                    rgba(173, 0, 255, 0) 100%
                );
            }
            &-legendary {
                background: linear-gradient(
                    90deg,
                    rgba(255, 184, 0, 0) 0%,
                    rgba(255, 184, 0, 0.5) 49.42%,
                    rgba(255, 209, 115, 0) 100%
                );
            }
            &-common {
                background: linear-gradient(
                    90deg,
                    rgba(198, 198, 198, 0) 0%,
                    rgba(213, 213, 213, 0.5) 50%,
                    rgba(204, 204, 204, 0) 100%
                );
            }
            &-active {
                filter: brightness(0.5);
            }
            &:hover {
                filter: brightness(0.85);
            }
        }
        &__info {
            line-height: K(30px);
        }
        &__main {
            flex: 1;
        }
        &__icon {
            height: K(90px);
            margin-right: K(10px);
        }
        &__lvl {
            text-align: center;
            color: #c1ac74;
        }
        &__lvl-word {
            font-size: K(15px);
        }
        &__lvl-number {
            font-size: K(25px);
        }
    }
    &__side {
        height: 100%;
        overflow-y: auto;
        padding: 10px;
        &:first-child {
            flex: 1;
            // padding-right: 10px;
        }
        &:last-child {
            flex: 2;
            // padding-left: 10px;
        }
    }
    &-avatar {
        position: relative;
        width: K(100px);
        height: K(100px);
        background-color: rgb(31, 31, 31);
        background-color: #1f1f1f;
        background-clip: content-box;
        padding: K(4px);
        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include BGI('../Images/ui/bg_hero_avatar_0.png');
            z-index: 2;
        }
        img.avatar {
            width: 90%;
            height: 90%;
            margin-left: K(5px);
            margin-top: K(3px);
            object-fit: cover;
            position: relative;
            z-index: 0;
        }
        .delete {
            display: none;
            @include BGI('../Images/ui/trash_bucket.svg');
            position: absolute;
            width: K(20px);
            height: K(20px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
        }
        &.delete-mouse {
            &:hover {
                cursor: pointer;
                .delete {
                    display: block;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: 90%;
                    height: 90%;
                    left: K(5px);
                    top: K(3px);
                    background: rgba(82, 82, 82, 0.31);
                    backdrop-filter: blur(4px);
                }
            }
        }
    }
    &-hero {
        position: relative;
        height: 100%;
        &__main {
            height: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__img {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;

            img {
                height: 100%;
                width: 100%;
                max-width: 500px;
                object-fit: contain;
            }
        }
        &__form {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            top: 35%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            background: linear-gradient(
                90deg,
                rgba(125, 120, 133, 0.904) 0%,
                rgba(124, 123, 129, 0.77) 50%,
                rgba(184, 179, 209, 0) 100%
            );
            height: 70%;
            border: 2px solid black;
            border-radius: 30px;
            text-align: center;
            padding: K(25px);

            h2 {
                font-size: K(30px);
                color: #ffffff;
            }
        }
        &__params {
            position: relative;
            padding: K(10px);
            text-align: center;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
        }
        &-info {
            $fsz: K(15px);
            position: absolute;
            overflow-y: auto;
            height: K(190px);
            bottom: 0;
            width: 100%;
            background: linear-gradient(
                90deg,
                rgba(146, 146, 146, 0) 0%,
                rgba(116, 116, 116, 0.77) 50%,
                rgba(178, 178, 178, 0) 100%
            );

            &__line {
                position: relative;
                width: 100%;
                height: K(5px);
                background: linear-gradient(
                    90deg,
                    rgba(100, 78, 99, 0) 0%,
                    #644e63 50%,
                    rgba(100, 78, 99, 0) 100%
                );
                opacity: 0.5;
            }

            &__stats {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
            }

            &__stat {
                padding: K(10px);
                text-align: center;
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
            }
            &__name {
                font-size: $fsz;
            }
            &__row {
                display: flex;
                align-items: center;
                margin-top: K(10px);
            }
            &__icon {
                width: K(20px);
            }
            &__num {
                font-size: $fsz;
                padding-left: 10px;
                &--isRed {
                    color: red;
                }
                &--isGreen {
                    color: rgb(129, 255, 10);
                }
            }
        }
    }
    &-equipment {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        &__item {
            position: relative;
            width: K(50px);
            height: K(50px);
            @include BGI('../Images/ui/equipment_slot.png');
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: K(8px);
            cursor: pointer;
            user-select: none;

            &_active {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    @include BGI('../Images/equipments/equipment_frame.png');
                }
            }
        }
        &__icon {
            width: 60%;
            &_default {
                width: 100%;
            }
        }
    }
    &__description {
        display: flex;
        align-items: center;
        &-text {
            font-size: K(24px);
            margin-left: K(40px);
        }
        &-icon {
            width: K(100px);
            object-fit: contain;
        }
    }
}
