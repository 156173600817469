.item {
    width: 11vh;
    aspect-ratio: 1/1;
    position: relative;
    background-color: transparent;

    &__info {
        margin-bottom: 5px;
    }

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-active {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                @include BGI('../Images/equipments/equipment_frame.png');
            }
        }

        &-applied {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                @include BGI('../Images/equipments/equipment_focus.png');
            }
        }
    }

    &__icon {
        height: 65%;
    }

    &__name {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__count {
        position: absolute;
        bottom: 0.2vh;
        right: 0.4vh;
        color: wheat;
        // background: rgba(0, 0, 0, 0.233);
        border-radius: 20px;
        font-size: 2vh;
    }

    @include inventoryItemBGPreset;

    &-tooltip {
        &__text {
            max-width: 300px;
            &-name {
                font-weight: 400;
                font-size: 38px;
                line-height: 62px;
            }

            &-rarity {
                font-weight: 400;
                font-size: 27px;
                line-height: 46px;
            }

            &-description {
                font-weight: 400;
                font-size: 23px;
                line-height: 39px;
            }
        }
    }

    &:disabled {
        color: black;
        cursor: initial;
    }
}
