.beta {
    background-image: url('../../Images/backgrounds/popup.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &__userbar {
        position: relative;
        top: 0;
        left: 0;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        padding: 0.5vw;
    }
}
