.king {
    &-level {
        width: 100%;
        padding: 2vh;

        &__row {
            display: flex;
            width: fit-content;
        }

        &__btns {
            display: flex;
            justify-content: center;
            margin: K(20px) 0;
        }

        &__inner {
            width: 100%;
            overflow-x: auto;
        }

        &-progress {
            width: 100%;
            height: K(50px);
            background: #282334;
            border-radius: K(8px);
            overflow: hidden;

            &__line {
                height: 100%;
                background: #f0a91f;
                width: K(300px);
            }
        }

        &-drop {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1vh 3vh 1vh 0;

            &:last-child {
                margin-right: 0;
            }

            @include inventoryItemBGPreset;

            &__inner {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                background-image: url('../../Images/ui/bg_king_level_items.png');
                background-size: 100%;
                position: relative;
                height: 60vh;
                aspect-ratio: 347/518;
                padding: 4vh;

                &-active {
                    &:after {
                        position: absolute;
                        content: '';
                        height: 19.7vh;
                        aspect-ratio: 386/170;
                        background-image: url('../../Images/ui/bg_king_level_items_glow_red.png');
                        top: -3vh;
                        background-size: 100%;
                    }
                }
            }

            &__item {
                height: 10vh;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 2;
                margin: 1vh;

                img {
                    width: 100%;
                    width: 100%;
                }
            }

            &__count {
                position: absolute;
                bottom: K(2px);
                border-radius: 0 0 K(10px) 0;
                right: K(3px);
                background: linear-gradient(
                    270deg,
                    #000000 0%,
                    rgba(0, 0, 0, 0) 81.82%
                );
                padding: K(5px);
                color: white;
                font-size: K(15px);
            }

            &__lvl {
                width: K(90px);
                height: K(90px);
                display: flex;
                font-size: K(25px);
                justify-content: center;
                align-items: center;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                color: white;
                background-image: url('../../Images/ui/bg_vip.png');
            }
        }
    }

    &-notification {
        height: 100%;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../Images/backgrounds/king_ticket.png');
        background-size: cover;
        background-position: center;

        &__ticket {
            img {
                height: 50vh;
            }
        }

        &__button {
            position: absolute;
            bottom: 50px;
            right: 50px;
        }

        &__text {
            &-h {
                color: #f2b006;
                font-size: 5vh;
                text-transform: uppercase;
            }

            &-power {
                color: #ffa928;
                font-size: 3vh;
                margin-top: 2vh;
                display: flex;
                align-items: center;

                img {
                    height: 5vh;
                    margin: 0 1vh 0 4vh;
                }

                span {
                    color: white;
                }
            }

            &-info {
                margin-top: 2vh;
                font-size: 3vh;
                color: #ffc369;
            }

            &-end {
                margin-top: 2vh;
                font-size: 5vh;
                color: #ea5425;
            }
        }
    }
}
