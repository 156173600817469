.game {
    &-nav {
        position: fixed;
        z-index: $uxZIndex;
        bottom: 20px;
        right: 20px;
        display: flex;
        &__item {
            width: K(100px);
            height: K(77px);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            margin-left: K(20px);

            background-image: url('../../Images/ui/main_screen_button.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
        }
        &__icon {
            position: absolute;
            bottom: 40%;
            height: 90%;
        }
        &__title {
            position: absolute;
            bottom: 20%;
            color: white;
            font-size: K(15px);
        }
    }
}
