.quest {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: K(30px);
    $border: 2px solid;
    $gradient: rgba(148, 148, 148, 0), #cbcbcb, rgba(148, 148, 148, 0);
    &__head {
        display: flex;
        padding-bottom: K(30px);
        align-items: center;
        &.adjust-top {
            padding-top: K(30px);
        }
        .head__half {
            display: flex;
            flex: 1;
        }
        .head__title {
            position: relative;
            width: 100%;
            .title-difficulty {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: K(120px);
                height: K(120px);
                padding: K(37px) K(40px);
                background-image: url('../../Images/ui/bg_vip.png');
                background-repeat: no-repeat;
                background-size: contain;
                color: #ffda51;
                text-shadow: 0px 5.55686px 1.38921px rgba(0, 0, 0, 0.25);
                text-align: center;
                display: flex;
                flex-direction: column;
                .difficulty-level {
                    font-size: K(25px);
                    padding-bottom: K(3px);
                }
            }
            .title-name {
                position: relative;
                font-size: K(35px);
                padding: K(10px) 0;
                text-align: center;
                color: #fff;
                &.adjust-left {
                    margin-left: K(70px);
                }
                &:before {
                    @include BGGold(90deg, 2px, 100%, 0, 0);
                }
                &:after {
                    @include BGGold(90deg, 2px, 100%, 100%, 0);
                }
            }
        }
        .head__badge {
            display: flex;
            flex-direction: column;
            color: #fff;
            margin-right: K(100px);
            &.adjust-left {
                padding-left: K(50px);
            }
            img.badge {
                height: K(90px);
                width: K(90px);
                object-fit: contain;
            }
            .badge-title {
                font-size: K(30px);
                margin-top: K(10px);
            }
            &.badge-small {
                img.badge {
                    height: K(60px);
                    width: K(60px);
                }
                .badge-title {
                    margin-top: K(40px);
                }
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: -webkit-fill-available;
        &.gradient {
            background: linear-gradient(
                90deg,
                rgba(146, 146, 146, 0) 0%,
                rgba(101, 97, 102, 0.61) 49.26%,
                rgba(146, 146, 146, 0) 100%
            );
            border-top: $border;
            @include borderGradient(linear-gradient(90deg, $gradient));
        }
        .heroes-list {
            flex: 1;
            width: 100%;
            overflow-y: scroll;
        }
    }
    &__half {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        height: 100%;
        overflow: auto;
        &.bigger {
            flex: 2;
        }
        &.indent {
            margin: 0 K(40px);
        }
        &.gradient {
            background: linear-gradient(
                90deg,
                rgba(146, 146, 146, 0) 0%,
                rgba(101, 97, 102, 0.61) 49.26%,
                rgba(146, 146, 146, 0) 100%
            );
            border-top: $border;
            @include borderGradient(linear-gradient(90deg, $gradient));
        }
        &.border-right {
            &:before {
                @include BGG(0deg, 90%, 2px, 10%, 99.5%);
            }
        }
    }
    &__title {
        font-size: K(45px);
        color: white;
        text-align: center;
        line-height: K(62px);
        &.gradient {
            border-bottom: $border;
            @include borderGradient(linear-gradient(90deg, $gradient));
        }
    }
    &-reward {
        display: flex;

        &__item {
            margin-left: K(20px);
            margin-top: K(20px);
        }
    }
    &-field {
        position: relative;

        &__info {
            position: absolute;
            bottom: 100%;
            left: 50%;
            background: rgba(0, 0, 0, 0.486);
            color: white;
            padding: 10px;
            border-radius: 5px;
            transition: 0.2s;

            transform: translate(-50%, -10px);
            opacity: 0;
            visibility: hidden;
            width: fit-content;
        }
        &:hover &__info {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, 0px);
        }
    }
    &__heroes {
        padding: K(40px) K(100px);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        //grid-column-gap: K(20px);
        grid-gap: K(20px);
        grid-template-rows: auto;
    }
    &__hero {
        position: relative;
        margin: 0 auto;
        .heroes-hero-info {
            top: 100%;
            transform: translateX(50%);
            background: rgb(35, 30, 47);
            right: 0;
            height: K(300px);
            width: K(400px);
            padding-top: K(20px);
            overflow-y: hidden;
            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include BGI('../Images/ui/ui_card_0.png');
                z-index: 2;
            }
            .heroes-hero-info__line {
                display: none;
            }
            .heroes-hero-info__stat {
                width: 50%;
            }
        }
    }
    .quest-image {
        width: K(500px);
        margin: 0 auto;
        object-fit: contain;
    }
    &__description {
        padding: K(40px) K(30px) 0 K(60px);
        p {
            font-size: K(20px);
            line-height: 1.3;
            color: #fff;
            margin-top: K(30px);
        }
    }
}
