.btn {
    user-select: none;
    color: #0c6010;
    font-size: K(20px);
    background: transparent;
    padding: 0;
    cursor: pointer;
    position: relative;

    &__loader {
        background: rgba(122, 122, 122, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &:hover {
        filter: brightness(0.7);
    }

    &:active {
        filter: brightness(0.5);
    }

    &:disabled {
        cursor: initial;
        filter: brightness(0.2);
    }

    &_fill {
        width: 100%;
    }
}
