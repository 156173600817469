.card {
    &-screen {
        $width: 400px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        display: none;
        overflow: auto;

        &__notification {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: K(10px) K(20px);
            max-width: 100%;
            color: white;
            background: #66ca66;
            opacity: 0;
            box-shadow: 4px 4px 0px 0px black;
            pointer-events: none;
            transition: opacity 0.2s;
            border-radius: 2px;

            &-active {
                opacity: 0.5;
            }
        }

        &__logo {
            position: absolute;
            top: 5%;
            left: 50%;
            z-index: 2;
            transform: translateX(-50%);
            height: 20vh;
        }

        &__inner {
            width: K($width);
            height: K($width * 1.0666);
            border-radius: K(10px);
            height: 100vh;
            max-height: K(420px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow-y: auto;
            display: none;
            flex-direction: column;
            z-index: 2;
            @include BGI('../Images/ui/card.png');

            &-show {
                display: flex;
            }
        }

        &_show {
            display: flex;
        }

        &__bg {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.247);
            z-index: 1;
            background-size: cover;

            &-show {
                display: initial;
            }

            &-art {
                background-image: url('../../Images/backgrounds/blur_art.png');
            }
        }

        &__header {
            height: K($width * 0.1267);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #f4d487;
            margin-top: K($width * 0.0267);
            position: relative;
            font-size: K(20px);
        }

        &__body {
            padding: K($width * 0.03125) K($width * 0.03125 * 2);
        }

        &__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0, 0, 0, 0.9);
            border-radius: K(10px);
            padding: K(20px);
            z-index: 2;
        }

        &__close {
            background-image: url('../../Images/ui/popup_close.png');
            background-size: 100% 100%;
            position: absolute;
            right: K(10px);
            top: 0;
            width: K(60px);
            height: K(50px);
            cursor: pointer;
        }
    }
}
