.lootbox {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    background: linear-gradient(
        90deg,
        rgba(146, 146, 146, 0) 0%,
        rgba(70, 70, 70, 0.61) 53.44%,
        rgba(146, 146, 146, 0) 100%
    );
    //transform: rotate(-180deg);
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 2fr;
    //grid-column-gap: K(50px);
    font-size: K(40px);
    color: white;
    //border: red 2px solid;
    padding: K(40px);
    &:before,
    &:after {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 1px;
    }
    &:before {
        top: 0;
        background: linear-gradient(
            90deg,
            rgba(148, 148, 148, 0) 0%,
            #cbcbcb 50.83%,
            rgba(148, 148, 148, 0) 100%
        );
    }
    &:after {
        bottom: 0;
        background: linear-gradient(
            90deg,
            rgba(148, 148, 148, 0) 0%,
            #cbcbcb 50.83%,
            rgba(148, 148, 148, 0) 100%
        );
    }
    &-image {
        grid-row: 1;
        position: relative;
        width: 100%;
        padding-top: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &-content {
        display: flex;
    }
    &-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        grid-column: 2;
        grid-row: 1;
        margin: 0 auto;

        .m-y-auto {
            margin: auto 0;
        }
        .m-t-auto {
            margin-top: auto;
        }
        &.indent-bottom {
            padding-bottom: K(40px);
        }
        &.text-center {
            text-align: center;
        }
        &.center {
            grid-column: 1 / 3;
        }
        &__header {
            line-height: K(50px);
            font-size: K(40px);
            text-align: center;
        }
        &__params {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            margin-bottom: auto;
            line-height: K(60px);
            font-size: K(35px);
            &.indent-left {
                margin-left: K(100px);
            }
        }
        &__text {
            margin-top: K(50px);
        }

        &__price {
            font-size: K(25px);
            display: flex;
            align-items: center;
            .currency-icon {
                height: K(30px);
                object-fit: contain;
                width: auto;
            }
            .cost {
                margin-left: K(10px);
            }
        }
    }

    &__buttons {
        display: flex;
        grid-column: 2;
        grid-row: 2;
        justify-content: flex-end;
        align-items: center;
        height: K(100px);
        &.center {
            justify-content: center;
        }
        > :nth-child(1n) {
            margin-right: K(20px);
        }
        > :last-child {
            margin-right: 0;
        }
        .button-normal {
            font-size: K(25px);
        }
        .body-button {
            display: flex;
            align-items: center;
            padding: 0 K(50px);
            &__price {
            }
        }
    }

    &-rarity-up {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        grid-column: 1/3;
        .lootbox-image {
            height: K(400px);
            padding-top: 0;
        }
        &__text-rarity {
            font-size: K(20px);
            margin-bottom: 2%;
        }
        &__button {
            width: K(100px);
        }
    }
    &-opened {
        &__list {
            grid-column: 1/3;
            margin: auto;
            height: 100%;
            width: 50%;
            display: grid;
            //overflow: hidden;
            max-height: 100%;
            grid-template-columns: repeat(5, 15%);
            grid-gap: 5%;
            .opened-item {
                display: flex;
                flex-direction: column;
                text-align: center;
                &__count {
                    margin-top: K(10px);
                    font-size: K(20px);
                }
                &__icon {
                    padding-top: 100%;
                    width: 100%;
                    border: 1px solid #fff;
                    position: relative;
                    img {
                        position: absolute;
                        width: 80%;
                        height: 80%;
                        object-fit: contain;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

.egg {
    &_hatch {
        //justify-content: center;
        align-items: center;
        &__rarity {
            text-transform: uppercase;
            font-size: K(50px);
            margin-top: K(70px);
        }
        &__description {
            margin-top: K(70px);
            width: 55%;
        }
        &__stats {
            width: 50%;
            margin-top: K(20px);
            .heroes-hero-info__stat {
                width: 50%;
            }
        }
    }
    &_power {
        .power__value {
            text-align: center;
            margin-bottom: K(20px);
        }
        .power__progress {
            display: flex;
            width: 100%;
            height: 40px;
            box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.35);
            border-radius: 8px;
            background: #4e4a50;
            .progress__stage {
                width: 16.666%;
                border-left: 2px solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-child {
                    border-left: unset;
                    &.passed {
                        border-bottom-left-radius: 8px;
                        border-top-left-radius: 8px;
                    }
                }
                &:last-child {
                    &.passed {
                        border-bottom-right-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                }
                &.passed {
                    background: #66cb29;
                }
            }
        }
    }
    &_chances {
        .chances__text {
            margin-top: K(30px);
        }
        .chances__list {
            display: flex;
            width: 100%;
            margin-top: K(20px);
            &_item {
                width: 20%;
                margin-left: K(10px);
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .chance_hero {
            background-image: url('../../../../Images/ui/bg_hero_avatar.png');
            background-size: contain;
            width: K(100px);
            height: K(100px);
            object-fit: contain;
            background-repeat: no-repeat;
        }
        .chance_value {
            margin-top: K(10px);
        }
    }
}

.lootbox_frame {
    position: relative;
    background: #44434d;
    &:before,
    &:after {
        content: '';
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 50.46%,
            rgba(255, 255, 255, 0) 100%
        );
        border-radius: 56.138px;
    }
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }
}
